import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  templateUrl: './unsupported-version-dialog.component.html',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
})
export class UnsupportedVersionDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<UnsupportedVersionDialogComponent>, // @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) {}
}
