<ng-container *transloco="let t">
  @switch (menuItem.type) {
    @case ('link') {
      <a
        routerLink="/{{ schoolId }}/{{ menuItem.state }}"
        [queryParams]="
          menuItem.state === 'settings/billing/pricing'
            ? { menu: 'true' }
            : null
        "
        (click)="clicked()"
        [attr.data-cy]="menuItem.dataCy"
        [matTooltip]="t(menuItem.name)"
        [matTooltipDisabled]="!isLgLayout"
        [ngClass]="{
          selected: isSelected(),
        }"
        matTooltipPosition="right"
      >
        <div class="flex w-full items-center lg:flex-col xl:flex-row">
          <div
            class="menu-icon flex items-center rounded-full p-2 lg:p-1 xl:p-2"
          >
            <ng-icon [name]="menuItem.icon" class=""></ng-icon>
          </div>
          <span
            class="lg:mb-1 lg:mt-0.5 lg:text-center lg:text-xs xl:mb-0 xl:mt-0 xl:text-sm"
            >{{
              isLgLayout && menuItem.lgName
                ? t(menuItem.lgName)
                : t(menuItem.name)
            }}</span
          >
        </div>
        <!-- <app-menu-item-badge class="lg:hidden xl:inline" *ngIf="menuItem.hint">
      {{ t(menuItem.hint) }}
    </app-menu-item-badge> -->
      </a>
    }
    @case ('external') {
      <a
        [href]="menuItem.state"
        target="_blank"
        (click)="clicked()"
        class="flex justify-between"
      >
        <div class="flex items-center">
          <div class="menu-icon flex items-center rounded-full p-2">
            <ng-icon [name]="menuItem.icon" class=""></ng-icon>
          </div>
          <span class="lg:hidden xl:inline">{{ t(menuItem.name) }}</span>
        </div>
        <!-- <app-menu-item-badge *ngIf="menuItem.hint">
      {{ t(menuItem.hint) }}
    </app-menu-item-badge> -->
      </a>
    }
    @case ('sub') {
      <a
        (click)="toggleExpanded()"
        class="relative flex cursor-pointer justify-between lg:justify-center xl:justify-between"
      >
        <div class="flex w-full items-center lg:flex-col xl:flex-row">
          <div
            class="menu-icon flex items-center rounded-full p-2 lg:p-1 xl:p-2"
          >
            <ng-icon [name]="menuItem.icon" class=""></ng-icon>
          </div>
          <span
            class="lg:mb-1 lg:mt-0.5 lg:text-center lg:text-xs xl:mb-0 xl:mt-0 xl:text-sm"
          >
            {{
              isLgLayout && menuItem.lgName
                ? t(menuItem.lgName)
                : t(menuItem.name)
            }}
          </span>
        </div>

        <div
          class="inset-y-0 -right-1 flex items-center lg:absolute lg:-mt-5 xl:relative xl:mt-0"
        >
          <ng-icon
            name="hero-chevron-down"
            class="menu-icon text-gray-400"
            [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'"
          ></ng-icon>
        </div>
      </a>
      @if (expanded) {
        <div @animateHeight class="overflow-hidden pl-4 lg:pl-0 xl:pl-4">
          @for (
            subItem of menuItem.children;
            track trackByFn(i, subItem);
            let i = $index
          ) {
            <app-menu-item
              (sideBarClicked)="sideBarClicked.emit()"
              [schoolId]="schoolId"
              [menuItem]="subItem"
              [menuIndex]="i"
              [isLgLayout]="isLgLayout"
              data-cy="sideNavNewTopItem"
            ></app-menu-item>
          }
        </div>
      }
    }
    @case ('saperator') {
      <div class="saperator text-gray-400 lg:hidden xl:inline">
        <span>{{ t(menuItem.name) }}</span>
      </div>
    }
  }
</ng-container>
