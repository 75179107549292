<div class="sidebar-margin pointer-events-auto flex flex-col gap-4 px-4 py-2">
  @if (failedPayment() && !hideBanner()) {
    @defer {
      <mspot-billing-topbar (closeTrigger)="hidePaymentBanner()" />
    }
  }

  @defer {
    @for (banner of banners(); track banner.id) {
      <mspot-banner-base
        [banner]="banner"
        (closeBanner)="onCloseBanner(banner.id)"
      />
    }
  }
</div>
