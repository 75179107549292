import { Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { combineLatest, from, map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ImportLoaderAdmin implements TranslocoLoader {
  getTranslation(langPath: string): Observable<Translation> {
    return this._getObs(langPath).pipe(
      map(([res1, res2]) => ({
        ...res1,
        admin: {
          ...res1.admin,
          ...res2,
        },
      })),
    );
  }

  private _getObs(langPath: string): Observable<Translation[]> {
    switch (langPath) {
      case 'en':
        return combineLatest([
          from(import(`assets/i18n/en.json`)),
          from(import(`apps/admin/src/assets/i18n/en.json`)),
        ]);
      default:
        return combineLatest([
          from(import(`assets/i18n/de.json`)),
          from(import(`apps/admin/src/assets/i18n/de.json`)),
        ]);
    }
  }
}
