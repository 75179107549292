import { Inject, Injectable } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { NotiService } from '@memberspot/frontend/shared/util/noti';
import { PROD } from '@memberspot/frontend/shared/util/tokens';
import { CF } from '@memberspot/models';
import { CollectionGuard } from 'akita-ng-fire';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { tap } from 'rxjs/operators';

import { CustomersService } from '../state/customers.service';
import { CustomerState } from '../state/customers.store';
import { checkCustomer } from '../subscription.helper';

@Injectable({
  providedIn: 'root',
})
export class CustomerGuard extends CollectionGuard<CustomerState> {
  constructor(
    protected override service: CustomersService,
    protected override router: Router,
    private _noti: NotiService,
    private _fns: Functions,
    private _gtmService: GoogleTagManagerService,
    @Inject(PROD) private _isProd: boolean,
  ) {
    super(service);
  }

  requestCustomerCreation(schoolId: string) {
    const callable = httpsCallable(this._fns, CF.SCHOOL.CREATE_SCHOOL_CUSTOMER);
    const data = {
      schoolId,
    };

    callable(data);
  }

  override canDeactivate() {
    this.service.resetActive();

    return super.canDeactivate();
  }

  protected override sync(next: ActivatedRouteSnapshot) {
    const { schoolId } = next.params;

    return this.service.syncActive({ id: schoolId }, { loading: true }).pipe(
      tap((cust) => {
        if (cust === undefined) {
          this.requestCustomerCreation(schoolId);
        } else {
          checkCustomer(cust, this._noti, this._gtmService);
        }
      }),
      tap(
        (c) =>
          this._isProd &&
          this._gtmService.pushTag({
            company_id: schoolId,
            company_name: c?.name,
            customer_plan: c?.plan,
            customer_status: c?.status,
          }),
      ),
    );
  }
}
