import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { NgIconsModule } from '@ng-icons/core';

import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';

@NgModule({
  imports: [CommonModule, RouterModule, NgIconsModule, TranslocoModule],
  declarations: [BreadcrumbComponent],
  exports: [BreadcrumbComponent],
})
export class FrontendSharedUiBreadcrumbModule {}
