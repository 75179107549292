import { provideHotToastConfig } from '@ngneat/hot-toast';

export const provideFrontendSharedUiHotToastConfig = () =>
  provideHotToastConfig({
    position: 'top-center',
    dismissible: true,
    className:
      'font-medium border-b-2 toast rounded-lg dark:text-gray-900 bg-dwhite',
    success: {
      style: {
        'border-color': 'var(--check-primary,#61d345)',
      },
    },
    error: {
      style: {
        'border-color': 'var(--error-primary,#ff4b4b)',
      },
    },
    warning: {
      style: {
        'border-color': 'var(--warn-primary,#ffab00)',
      },
    },
  });
