@if (!loaded()) {
  <div class="loader-container">
    <div
      class="loader-logo-container"
      role="status"
      aria-labelledby="spinner-title"
    >
      <svg
        height="48"
        width="48"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        class="Spinner-svg"
      >
        <title id="spinner-title"></title>
        <ellipse
          cx="12"
          cy="12"
          rx="10"
          ry="10"
          class="spinner-ellipse"
        ></ellipse>
      </svg>
    </div>
  </div>
}

<router-outlet> </router-outlet>

@defer (when isDevMode) {
  <angular-query-devtools />
}
