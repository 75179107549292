import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  input,
  Output,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { Banner, BannerVariant } from '@memberspot/admin/shared/util/model';
import { fadeInFromTopAnimation } from '@memberspot/frontend/shared/util/animations';
import { IS_XS } from '@memberspot/frontend/shared/util/layout';
import { NgIcon } from '@ng-icons/core';

import { BannerBaseStyleComponent } from '../banner-base-style/banner-base-style.component';

@Component({
  selector: 'mspot-banner-base',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NgIcon,
    TranslocoDirective,
    BannerBaseStyleComponent,
  ],
  templateUrl: './banner-base.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInFromTopAnimation],
  host: {
    '[@fadeInFromTopAnimation]': '',
  },
})
export class BannerBaseComponent {
  banner = input.required<Banner>();
  isMobile = toSignal(inject(IS_XS));

  @Output() closeBanner = new EventEmitter<void>();

  getIcon(variant: BannerVariant) {
    switch (variant) {
      case 'warning':
        return 'heroExclamationTriangle';
      case 'success':
        return 'heroCheckCircle';
      case 'error':
        return 'heroXCircle';
      case 'info':
      case 'dpa':
        return 'heroInformationCircle';
    }
  }
}
