import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogSize } from '@memberspot/frontend/shared/util/dialog';

import { TermsContentComponent } from '../terms-content/terms-content.component';

@Component({
  selector: 'mspot-terms-dialog',
  templateUrl: './terms-dialog.component.html',
  standalone: true,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TermsContentComponent],
})
export class TermsDialogComponent {
  private _dialogRef = inject(MatDialogRef<TermsDialogComponent>);

  rejectToggle(isRejectBtnClicked: boolean) {
    if (isRejectBtnClicked) {
      this._dialogRef.addPanelClass(DialogSize.SIZE_500);
    } else {
      this._dialogRef.removePanelClass(DialogSize.SIZE_500);
    }
  }

  agree() {
    this._dialogRef.close();
  }
}
