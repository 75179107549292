@if (schoolId) {
  <div>
    @if (isHandset) {
      <mspot-mobile-sidebar
        [opened]="mobileMenuOpened$ | async"
        (closeSidebar)="toggleNav()"
      >
        @if (mobileMenuOpened$ | async) {
          <app-sidebar-new
            [school]="school()"
            [schools]="schools"
            [isLg]="isLg"
            [isHandset]="isHandset"
            [darkSidebar]="false"
            class="app-sidebar"
            (sideBarToggled)="toggleNav()"
          ></app-sidebar-new>
        }
      </mspot-mobile-sidebar>
    } @else {
      <mspot-desktop-sidebar>
        <app-sidebar-new
          [school]="school()"
          [schools]="schools"
          [isLg]="isLg"
          [scrolled]="false"
          [darkSidebar]="false"
          class="app-sidebar"
        ></app-sidebar-new>
      </mspot-desktop-sidebar>
    }
  </div>
}

<div
  class="page-wrapper bg-gray-50"
  [class]="schoolId ? 'lg:pl-[76px] xl:pl-[280px] 2xl:pl-[320px]' : ''"
>
  <div
    class="header-bg header-blur-light toolbar header-toolbar topbar sticky top-0 z-30 flex items-center justify-center"
    data-cy="mspotAdminToolbarTopFull"
  >
    <div
      class="h-full w-full px-4 lg:px-8"
      [style.maxWidth]="maxWidth || '1144px'"
    >
      <div class="flex h-full w-full items-center justify-between">
        <div class="flex min-w-0 items-center">
          @if (isHandset) {
            <mspot-header-mobile-back-icon class="btn-gray--icon-light">
              @if (school()) {
                <button (click)="toggleNav()" class="flex h-full items-center">
                  @if (school().icon) {
                    <img
                      [src]="school()?.icon"
                      width="32"
                      class="rounded-xl"
                      mspotImgLoadErr
                    />
                  } @else {
                    <div
                      class="flex h-8 w-8 items-center justify-center rounded-full border border-solid border-gray-600 bg-white text-xl text-black"
                    >
                      <span>{{ school()?.name && school().name[0] }}</span>
                    </div>
                  }
                </button>
              }
            </mspot-header-mobile-back-icon>
          }
        </div>
        <app-header
          [schoolId]="schoolId"
          [isHandset]="isHandset"
          [darkMode]="darkMode"
          (toggleNav)="toggleNav()"
          class="lg:w-full"
        ></app-header>
      </div>
    </div>
  </div>

  <div class="page-content" [style.maxWidth]="maxWidth || '1144px'">
    @if (!isHandset) {
      <app-breadcrumb></app-breadcrumb>
    }
    <router-outlet></router-outlet>
  </div>
</div>

<mspot-portal-sidebar [opened]="portalLoaderService.showPortal$ | async">
  <mspot-portal-loader></mspot-portal-loader>
</mspot-portal-sidebar>

<mspot-banner-alert-handler />
