<ng-container *transloco="let t">
  <div
    class="text-mspot-neutral-950 border-mspot-neutral-200 border-b font-semibold"
    [ngClass]="isBottomSheet() ? 'px-4 py-5' : 'p-6'"
  >
    {{ t('terms.title') }}
  </div>

  <div
    class="text-mspot-neutral-800"
    [ngClass]="isBottomSheet() ? 'mx-4 my-5' : 'm-6'"
  >
    {{ t('terms.text') }}
  </div>

  <div
    class="text-mspot-neutral-800"
    [ngClass]="isBottomSheet() ? 'mx-4 mb-5' : 'mx-6 mb-6'"
  >
    @if (isRejected) {
      <div class="mb-6">{{ t('terms.supportText') }}</div>
      <a
        class="text-mspot-information-600 underline"
        href="mailto:info@memberspot.de"
        >info&#64;memberspot.de</a
      >
    } @else {
      <div
        class="bg-mspot-neutral-50 border-mspot-neutral-200 overflow-y-auto rounded border px-4 py-5"
        [ngClass]="
          isBottomSheet() ? 'h-[12rem] hyphens-auto' : 'max-h-[33.25rem]'
        "
      >
        <div
          class="mb-6 flex flex-col items-center text-center"
          [innerHTML]="t('terms.contentTitle')"
        ></div>

        <div class="content" [innerHTML]="t('terms.content')"></div>
      </div>
    }
  </div>

  <div
    class="border-mspot-neutral-200 flex justify-between gap-2 border-t"
    [ngClass]="isBottomSheet() ? 'px-4 pb-12 pt-5' : 'p-6'"
  >
    @if (isRejected) {
      <button (click)="toggleReject(false)" class="btn btn-primary ml-auto">
        {{ t('terms.backToAgbText') }}
      </button>
    } @else {
      <button
        (click)="toggleReject(true)"
        class="btn btn-white"
        [class.w-full]="isBottomSheet()"
      >
        {{ t('text.decline') }}
      </button>
      <button
        (click)="agree()"
        class="btn btn-primary"
        [class.w-full]="isBottomSheet()"
      >
        {{ t('text.accept') }}
      </button>
    }
  </div>
</ng-container>
