import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { TRANSLOCO_SCOPE, TranslocoDirective } from '@jsverse/transloco';
import { scopeLoader } from '@memberspot/frontend/shared/util/translation';

// https://tools.pdf24.org/en/pdf-to-html

@Component({
  selector: 'mspot-terms-content',
  standalone: true,
  imports: [TranslocoDirective, NgClass],
  templateUrl: './terms-content.component.html',
  styleUrl: `./terms-content.component.scss`,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'terms',
        loader: scopeLoader(
          (lang: string) => import(`../../assets/i18n/${lang}.json`),
        ),
        canReRender: true,
      },
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsContentComponent {
  isBottomSheet = input<boolean>(false);
  isRejected = false;
  onRejectToggle = output<boolean>();
  onAgree = output();

  toggleReject(toggleReject: boolean) {
    this.isRejected = toggleReject;
    this.onRejectToggle.emit(toggleReject);
  }

  agree() {
    this.onAgree.emit();
  }
}
