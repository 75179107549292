import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { TranslocoModule } from '@jsverse/transloco';
import { getHeightAnimationEnterLeave } from '@memberspot/frontend/shared/util/animations';
import { NgIconComponent } from '@ng-icons/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, tap } from 'rxjs/operators';

import { Menu } from '../../../menu-items/model/menu-items.model';
import { SidebarService } from '../sidebar.service';
import { SidebarActiveService } from '../sidebar-active.service';

@UntilDestroy()
@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterModule,
    MatTooltipModule,
    NgIconComponent,
    TranslocoModule,
    CommonModule,
  ],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(-90deg)' })),
      state('expanded', style({ transform: 'rotate(0deg)' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)'),
      ),
    ]),
    getHeightAnimationEnterLeave('0px'),
  ],
})
export class MenuItemComponent implements OnInit {
  @Input() menuItem: Menu;
  @Input() schoolId: string;
  @Input() menuIndex: number;
  @Input() isLgLayout: boolean;

  @Output() sideBarClicked = new EventEmitter();

  isSelected = signal(false);

  expanded = false;

  constructor(
    private _sidebarService: SidebarService,
    private _routerQuery: RouterQuery,
    private _changeDetectorRef: ChangeDetectorRef,
    private _sidebarActiveService: SidebarActiveService,
  ) {}

  ngOnInit(): void {
    if (this.menuItem.type === 'sub') {
      this._sidebarService.openedSubmenu$
        .pipe(
          tap((res) => (this.expanded = res === this.menuIndex)),
          untilDestroyed(this),
        )
        .subscribe(() => this._changeDetectorRef.markForCheck());
    }

    if (this.menuItem.type === 'sub' && this.menuItem.openRoutes) {
      this._routerQuery
        .select((routerState) => routerState.state.urlAfterRedirects)
        .pipe(
          filter((url) => {
            for (const openRoute of this.menuItem.openRoutes) {
              if (url.includes(openRoute)) {
                return true;
              }
            }

            return false;
          }),
          untilDestroyed(this),
        )
        .subscribe(() => {
          // this.expanded = true;
          if (!this.expanded) {
            this.toggleExpanded();
            this._changeDetectorRef.markForCheck();
          }
        });
    }

    this._sidebarActiveService.activeMenuItem$
      .pipe(untilDestroyed(this))
      .subscribe((item) => {
        this.isSelected.set(item?.state === this.menuItem.state);
      });
  }

  toggleExpanded() {
    this._sidebarService.openedSubmenu$.next(
      !this.expanded ? this.menuIndex : null,
    );
  }

  clicked() {
    this.sideBarClicked.emit();
  }

  trackByFn(index: number, item: Menu) {
    return item.state;
  }
}
