import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

import { TermsContentComponent } from '../terms-content/terms-content.component';

@Component({
  selector: 'mspot-terms-bottom-sheet',
  standalone: true,
  imports: [TermsContentComponent],
  templateUrl: './terms-bottom-sheet.component.html',
  styles: `
    :host {
      .custom-bottom-sheet-class .mat-bottom-sheet-container {
        padding: 0 !important; /* Remove all padding */
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsBottomSheetComponent {
  private _bottomSheetRef =
    inject<MatBottomSheetRef<TermsBottomSheetComponent>>(MatBottomSheetRef);

  agree() {
    this._bottomSheetRef.dismiss();
  }
}
