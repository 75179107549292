export const ignoredAdminErrors: (string | RegExp)[] | undefined = [
  /The play\(\) request was interrupted by a call to pause\(\)/,
  /The play\(\) request was interrupted by a new load request/,
  /AbortError: The fetching process for the media resource was aborted by the user agent at the user's request/,
  /AbortError: The operation was aborted/,
  /Failed to load resource: the server responded with a status of 404/,
  /The popup has been closed by the user before finalizing the operation/,
  /The password is invalid or the user does not have a password/,
  /There is no user record corresponding to this identifier\. The user may have been deleted/,
  /The email address is already in use by another account/,
  /Access to this account has been temporarily disabled due to many failed login attempts/,
  /This operation has been cancelled due to another conflicting popup being opened/,
  /Uncaught \(in promise\): Error: Unknown player\. Probably unloaded/,
  /this\.enter is not a function/,
  /The object is in an invalid state(.|\n)*?webkitEnterFullscreen/,
  /this\.target\.webkitEnterFullscreen is not a function/,
  /The object is in an invalid state/,
  'Uncaught (in promise): [object Undefined]',
  /TypeError: Cannot read properties of undefined \(reading 'text'\)/,
  /TypeError: Cannot read properties of null \((reading 'progress')\)/, //plyr fehler
  /FetchEvent.respondWith received an error: Load failed/, // iOS PWA
  /TypeError: Load failed/, // iOS PWA
  /TypeError: Die Netzwerkverbindung wurde unterbrochen./, // iOS PWA
  /FetchEvent.respondWith received an error: Die Netzwerkverbindung wurde unterbrochen./, // iOS PWA
  'Non-Error exception captured',
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  'Firebase: Error (auth/popup-closed-by-user).',
  'Firebase: Error (auth/cancelled-popup-request).',
  'Firebase: Error (auth/popup-blocked).',
  'Firebase: Error (auth/network-request-failed).',
  /Error: Uncaught (in promise): TypeError: pe.frames[0] is undefined/,
  /SyntaxError: expected expression, got '='/,
  /SyntaxError: Unexpected token '='/,
  /Uncaught \(in promise\): ChunkLoadError: Loading chunk/,
  'provider destroyed',
];
