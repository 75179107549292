import { Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { from, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ImportLoader implements TranslocoLoader {
  getTranslation(langPath: string): Observable<Translation> {
    switch (langPath) {
      case 'en':
        return from(import(`assets/i18n/en.json`));
      default:
        return from(import(`assets/i18n/de.json`));
    }
  }
}
