import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { FrontendSharedUtilImageModule } from '@memberspot/frontend/shared/util/image';
import { NgIconComponent } from '@ng-icons/core';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'mspot-header-mobile-back-icon',
  standalone: true,
  imports: [
    RouterLink,
    NgIconComponent,
    AsyncPipe,
    TranslocoModule,
    FrontendSharedUtilImageModule,
  ],
  templateUrl: './header-mobile-back-icon.component.html',
  styles: [],
  host: {
    class: 'min-w-0',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMobileBackIconComponent {
  breadcrumbs$ = inject(BreadcrumbService).breadcrumbs$;
}
