const TAG_REGEX = new RegExp(
  '^(?<year>[0-9]{4})-(?<month>[0-9]{2})-(?<day>[0-9]{2})-(?<hour>[0-9]{2})(?<minute>[0-9]{2})(/.*)?',
);

export const isVersionLower = (
  version: string,
  targetVersion: string,
): boolean => {
  console.log(version, targetVersion);

  if (!TAG_REGEX.test(version) && TAG_REGEX.test(targetVersion)) {
    // transitional cases: until first tag version is new min-version
    // this should not happen
    console.warn(
      `[isVersionLower] transitional case: version=${version}, targetVersion=${targetVersion}, LAST_SEMVER=${LAST_SEMVER}`,
    );

    return compareSemver(version, LAST_SEMVER);
  }

  if (!TAG_REGEX.test(version) && !TAG_REGEX.test(targetVersion)) {
    // Backwards compatibility
    return compareSemver(version, targetVersion);
  }

  if (TAG_REGEX.test(version) && !TAG_REGEX.test(targetVersion)) {
    // Backwards compatibility
    return false;
  }

  return getTagDateAsTimestamp(version) <= getTagDateAsTimestamp(targetVersion);
};

const getTagDateAsTimestamp = (tag: string) => {
  const { year, month, day, hour, minute } = tag.match(TAG_REGEX).groups;

  if (!year || !month || !day || !hour || !minute) {
    throw new Error('Invalid tag');
  }

  const date = new Date(`${year}-${month}-${day} ${hour}:${minute}`);

  return date.getTime();
};

/**
 * @deprecated
 */
const compareSemver = (version: string, targetVersion: string): boolean => {
  const versionParts = version.split('.').map(Number);
  const targetVersionParts = targetVersion.split('.').map(Number);

  for (
    let i = 0;
    i < Math.max(versionParts.length, targetVersionParts.length);
    i++
  ) {
    const part1 = versionParts[i] || 0;
    const part2 = targetVersionParts[i] || 0;

    if (part1 < part2) {
      return true;
    } else if (part1 > part2) {
      return false;
    }
  }

  return false;
};

/**
 * @deprecated
 */
export const LAST_SEMVER = '8.5.0' as const;
