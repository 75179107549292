import { Overlay } from '@angular/cdk/overlay';
import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AuthQuery } from '@memberspot/admin/shared/data-access/auth';
import { HttpBackendService } from '@memberspot/frontend/shared/data-access/common';
import {
  DialogBaseService,
  DialogSize,
} from '@memberspot/frontend/shared/util/dialog';
import { IS_XS } from '@memberspot/frontend/shared/util/layout';
import { AdminUserEndpoints } from '@memberspot/shared/model/admin-user';
import { LATEST_TERMS_VERSION } from '@memberspot/shared/util/constants';
import { filter, first, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { TermsBottomSheetComponent } from './terms-bottom-sheet/terms-bottom-sheet.component';
import { TermsDialogComponent } from './terms-dialog/terms-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class TermsService {
  private _isMobile = toSignal(inject(IS_XS));
  private _dialogService = inject(DialogBaseService);
  private _bottomSheet = inject(MatBottomSheet);
  private _http = inject(HttpBackendService);
  private _authQuery = inject(AuthQuery);
  private _overlay = inject(Overlay);

  checkIfCurrentTermsAccepted() {
    this._authQuery
      .selectProfile()
      .pipe(
        filter((u) => !!u),
        first(),
        switchMap((u) =>
          (u.termsVersion || 0) >= LATEST_TERMS_VERSION
            ? of(true)
            : this.openTermsAgreement(),
        ),
      )
      .subscribe();
  }

  openTermsAgreement() {
    if (this._isMobile()) {
      return this._bottomSheet
        .open(TermsBottomSheetComponent, {
          panelClass: 'mat-bottom-sheet-container-p-0',
          disableClose: true,
          autoFocus: false,
          scrollStrategy: this._overlay.scrollStrategies.block(),
        })
        .afterDismissed()
        .pipe(
          switchMap(() =>
            this._http.generic(
              AdminUserEndpoints.UPDATE_TERMS({ accepted: true }),
            ),
          ),
        );
    } else {
      return this._dialogService
        .open(
          TermsDialogComponent,
          DialogSize.SIZE_NO_PADDING,
          null,
          true,
          false,
          {
            maxWidth: 960,
            maxHeight: 800,
            width: 'calc(100% - 32px)',
            scrollStrategy: this._overlay.scrollStrategies.block(),
          },
        )
        .pipe(
          switchMap(() =>
            this._http.generic(
              AdminUserEndpoints.UPDATE_TERMS({ accepted: true }),
            ),
          ),
        );
    }
  }
}
