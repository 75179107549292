import { inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, merge, Subject } from 'rxjs';
import { filter, shareReplay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SoftwareUpdateService {
  private _swUpdate = inject(SwUpdate);

  snooze = new Subject();
  newUpdateReady$ = new BehaviorSubject(false);

  snooze$ = this.snooze.asObservable();

  readonly unrecoverableError$ = this._swUpdate.unrecoverable;

  readonly updateAvailable$ = merge(
    this._swUpdate.versionUpdates.pipe(
      tap((evt) => {
        switch (evt.type) {
          case 'VERSION_DETECTED':
            console.log(`Downloading new app version: ${evt.version.hash}`);

            break;
          case 'VERSION_READY':
            console.log(`Current app version: ${evt.currentVersion.hash}`);
            console.log(
              `New app version ready for use: ${evt.latestVersion.hash}`,
            );

            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.log(
              `Failed to install app version '${evt.version.hash}': ${evt.error}`,
            );

            break;
        }
      }),
      filter((evt) => evt.type === 'VERSION_READY'),
    ),
    // for debugging enable this line
    // of(true)
  ).pipe(
    tap(() => this.newUpdateReady$.next(true)),
    shareReplay({ refCount: true }),
  );
}
