import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { SchoolsQuery } from '@memberspot/admin/shared/data-access/schools';
import { GUIDE_VERSION } from '@memberspot/shared/model/admin-onboarding';

import { AdminOnboardingService } from '../admin-onboarding.service';

@Component({
  selector: 'mspot-admin-onboarding-global-info',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './global-info.component.html',
  providers: [AdminOnboardingService],
  imports: [TranslocoModule, RouterLink],
})
export class AdminOnboardingGlobalInfoComponent {
  private _adminOnboardingService: AdminOnboardingService = inject(
    AdminOnboardingService,
  );

  public version = input.required<GUIDE_VERSION>();

  public translationKey = computed(() => `onboarding.${this.version()}`);
  public readonly schoolId = toSignal(inject(SchoolsQuery).selectActiveId());
  public progress = this._adminOnboardingService.progress;
}
