/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { UserAdminService } from '@memberspot/admin/school-user/data-access/users';
import {
  AuthQuery,
  AuthService,
} from '@memberspot/admin/shared/data-access/auth';
import {
  SchoolsQuery,
  SchoolsService,
} from '@memberspot/admin/shared/data-access/schools';
import { TermsService } from '@memberspot/admin/shared/service/terms';
import { School } from '@memberspot/shared/model/school';
import { asyncScheduler, Observable, of } from 'rxjs';
import {
  auditTime,
  filter,
  first,
  map,
  observeOn,
  switchMap,
} from 'rxjs/operators';
import { SubSink } from 'subsink';

@Injectable({
  providedIn: 'root',
})
export class SchoolAdminGuard {
  private _subSink = new SubSink();

  constructor(
    private _router: Router,
    private _schoolsQuery: SchoolsQuery,
    private _schoolsService: SchoolsService,
    private _userService: UserAdminService,
    private _authQuery: AuthQuery,
    private _authService: AuthService,
    private _termsService: TermsService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    const schoolId = route.paramMap.get('schoolId');

    return this.checkIfAdmin(schoolId).pipe(
      switchMap((res) => (res ? of(res) : this.checkIfSuperAdmin(schoolId))),
    );
  }

  checkIfAdmin(schoolId: string) {
    return this._schoolsQuery.selectLoading().pipe(
      filter((l) => !l),
      observeOn(asyncScheduler),
      switchMap(() => this._schoolsQuery.selectEntity(schoolId)),
      map((school) => {
        if (school?.expiresAt) {
          return false;
        }

        if (school?.admins?.includes(this._authQuery.getUID())) {
          this.startSideEffects(school, false);

          return true;
        }

        return false;
      }),
      first(),
    );
  }

  checkIfSuperAdmin(schoolId: string) {
    return this._authQuery
      .selectReadOrSuperAdmin()
      .pipe(
        switchMap((isSuperAdmin) =>
          isSuperAdmin
            ? this.startSuperAdminSchoolSync(schoolId)
            : of(this.getOverviewUrlTree()),
        ),
      );
  }

  startSuperAdminSchoolSync(schoolId: string) {
    if (!this._schoolsQuery.hasEntity(schoolId)) {
      this._subSink.sink = this._schoolsService
        .syncSingleSchool(schoolId)
        .subscribe();
    }

    return this._schoolsQuery.selectLoading().pipe(
      filter((l) => !l),
      switchMap(() => this._schoolsQuery.selectEntity(schoolId)),
      auditTime(100),
      first(),
      map((school) => {
        if (school?.id) {
          this.startSideEffects(school, true);

          return true;
        }

        return this.getOverviewUrlTree();
      }),
    );
  }

  startSideEffects(school: School, isSuperAdmin: boolean) {
    this._schoolsService.setSchoolActive(school.id);

    if (!isSuperAdmin) {
      this.checkAdminToken(school.id);
    }

    this._userService.updateLastLogin(school);

    this._termsService.checkIfCurrentTermsAccepted();

    this.setUpSchoolWatch(school.id);
  }

  getOverviewUrlTree() {
    return this._router.parseUrl('/schools?manual=true');
  }

  redirectToOverView() {
    this._router.navigate(['/schools'], { queryParams: { manual: true } });
  }

  setUpSchoolWatch(schoolId: string) {
    this._subSink.sink = this._schoolsQuery
      .selectEntity(schoolId)
      .subscribe((school) => {
        if (!school) {
          this.redirectToOverView();
        }
      });
  }

  async checkAdminToken(schoolId: string) {
    this._authQuery.roles$
      .pipe(
        first(),
        filter((roles) => !(roles?.admin && roles.admin[schoolId])),
      )
      .subscribe((res) => res && this._authService.refreshToken());
  }

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ):
    | boolean
    | import('@angular/router').UrlTree
    | Observable<boolean | import('@angular/router').UrlTree>
    | Promise<boolean | import('@angular/router').UrlTree> {
    console.log('setting school inactive');
    this._schoolsService.setSchoolActive(null);
    this._subSink.unsubscribe();

    return true;
  }
}
