import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
} from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { TruncatePipe } from '@memberspot/frontend/shared/ui/tools';
import { School } from '@memberspot/shared/model/school';
import { NgIconComponent } from '@ng-icons/core';

@Component({
  selector: 'app-school-logo',
  templateUrl: './school-logo.component.html',
  standalone: true,
  imports: [
    MatMenuModule,
    CommonModule,
    NgIconComponent,
    RouterLink,
    TruncatePipe,
    TranslocoModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolLogoComponent {
  school = input<School>();
  schools = input<School[]>();

  sortedSchools = computed(() =>
    this.schools()?.sort((a, b) => a.name.localeCompare(b.name)),
  );

  itemClicked = output();

  createLink(schoolId?: string) {
    return schoolId ? schoolId : 'schools';
  }

  trackBy(index: number, item: School) {
    return item.id;
  }
}
