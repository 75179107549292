import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  input,
} from '@angular/core';
import { User } from '@angular/fire/auth';
import { Router, RouterModule } from '@angular/router';
import { SchoolAdminPermissionQuery } from '@memberspot/admin/shared/data-access/school-admin-permission';
import { BannerAlertHandlerComponent } from '@memberspot/admin/shared/service/system-messages';
import { HeaderMobileBackIconComponent } from '@memberspot/frontend/shared/ui/breadcrumb';
import {
  FrontendSharedUiPortalLoaderModule,
  PortalLoaderService,
} from '@memberspot/frontend/shared/ui/portal-loader';
import { FrontendSharedUiSidebarLayoutModule } from '@memberspot/frontend/shared/ui/sidebar-layout';
import { School } from '@memberspot/shared/model/school';
import { NgIconComponent } from '@ng-icons/core';
import { BehaviorSubject } from 'rxjs';

import { MenuItemsService } from '../menu-items/menu-items-service';
import { AppBreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { AppHeaderComponent } from './header/header.component';
import { AppSidebarNewComponent } from './sidebar-new/sidebar-new.component';

@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: ['full.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'block',
  },
  imports: [
    AppSidebarNewComponent,
    AppSidebarNewComponent,
    AppBreadcrumbComponent,
    AppHeaderComponent,
    FrontendSharedUiPortalLoaderModule,
    RouterModule,
    NgIconComponent,
    FrontendSharedUiSidebarLayoutModule,
    CommonModule,
    HeaderMobileBackIconComponent,
    BannerAlertHandlerComponent,
  ],
})
export class FullComponent {
  private _router = inject(Router);

  @Input() schoolId?: string;

  school = input<School | null>(null);
  @Input() schools: School[] = [];

  @Input() darkMode?: boolean;
  @Input() darkSidebar?: boolean;

  @Input() user: User;

  @Input() isHandset?: boolean;
  @Input() isLg?: boolean;
  @Input() scrolled?: boolean;

  @Input() maxWidth?: string;

  mobileMenuOpened$ = new BehaviorSubject(false);

  loading$ = inject(SchoolAdminPermissionQuery).selectLoading();

  constructor(
    public menuItems: MenuItemsService,
    public portalLoaderService: PortalLoaderService,
  ) {}

  toggleNav() {
    this.mobileMenuOpened$.next(!this.mobileMenuOpened$.getValue());
  }
}
