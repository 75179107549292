import { Menu } from './menu-items.model';

export const MENU_ITEMS: Menu[] = [
  {
    state: 'home',
    name: 'admin.menuItems.home.name',
    lgName: 'admin.menuItems.home.lgName',
    type: 'link',
    dataCy: 'menuHome',
    icon: 'hero-home',
    permissionModule: ['home'],
    alwaysVisible: true,
  },
  {
    state: 'dashboard',
    name: 'admin.menuItems.dashboard.name',
    lgName: 'admin.menuItems.dashboard.lgName',
    type: 'link',
    dataCy: 'menuDashboard',
    icon: 'hero-presentation-chart-bar',
    permissionModule: ['dashboard'],
  },
  {
    state: 'content',
    name: 'admin.menuItems.content.name',
    lgName: 'admin.menuItems.content.lgName',
    type: 'sub',
    dataCy: 'menuContent',
    icon: 'hero-folder-open',
    alwaysVisible: true,
    permissionModule: ['allCoursesPermission'],
    openRoutes: ['/courses', '/spaces', '/portals', '/pages', '/navigation'],
    children: [
      {
        state: 'courses',
        name: 'admin.menuItems.courses.name',
        lgName: 'admin.menuItems.courses.lgName',
        type: 'link',
        dataCy: 'menuCourses',
        icon: 'hero-academic-cap',
        alwaysVisible: true,
        permissionModule: ['allCoursesPermission'],
      },
      {
        state: 'spaces',
        name: 'admin.menuItems.spaces.name',
        type: 'link',
        dataCy: 'menuSpaces',
        icon: 'hero-chat-bubble-left-ellipsis',
        permissionModule: ['spaces'],
      },
      {
        state: 'portals',
        name: 'admin.menuItems.clientDashboards.name',
        lgName: 'admin.menuItems.clientDashboards.lgName',
        type: 'link',
        dataCy: 'clientDashboards',
        icon: 'hero-computer-desktop',
        permissionModule: ['portals'],
      },
      {
        state: 'pages',
        name: 'admin.menuItems.pages.name',
        type: 'link',
        dataCy: 'pages',
        icon: 'hero-newspaper',
        permissionModule: ['pages'],
      },
      {
        state: 'navigation',
        name: 'admin.menuItems.sidebar.name',
        type: 'link',
        dataCy: 'sidebar',
        icon: 'hero-list-bullet',
        permissionModule: ['sidenav'],
      },
    ],
  },
  {
    state: 'offers',
    name: 'admin.menuItems.offers.name',
    type: 'link',
    dataCy: 'menuOffers',
    icon: 'hero-key',
    permissionModule: ['access'],
  },
  {
    state: 'users',
    name: 'admin.menuItems.users.name',
    lgName: 'admin.menuItems.users.lgName',
    type: 'link',
    dataCy: 'menuMembers',
    icon: 'hero-user-group',
    permissionModule: ['members'],
  },
  {
    state: 'design',
    name: 'admin.menuItems.design.name',
    lgName: 'admin.menuItems.design.lgName',
    type: 'sub',
    dataCy: 'menuContent',
    icon: 'hero-paint-brush',
    permissionModule: ['yourArea'],
    openRoutes: ['/preview'],
    children: [
      {
        name: 'admin.menuItems.startPage.name',
        lgName: 'admin.menuItems.startPage.lgName',
        type: 'link',
        dataCy: 'menuMemberarea',
        state: 'preview/start',
        icon: 'hero-home',
        permissionModule: ['yourArea'],
      },
      {
        name: 'admin.menuItems.courseDesign.name',
        lgName: 'admin.menuItems.courseDesign.lgName',
        type: 'link',
        dataCy: 'menuMemberarea',
        state: 'preview/overview',
        icon: 'hero-square-3-stack-3d',
        permissionModule: ['yourArea'],
      },
      {
        name: 'admin.menuItems.boxes.name',
        type: 'link',
        dataCy: 'menuMemberareaCourseDetails',
        state: 'preview/details',
        icon: 'hero-rectangle-group',
        permissionModule: ['yourArea'],
      },
      {
        name: 'admin.menuItems.colors.name',
        lgName: 'admin.menuItems.colors.lgName',
        type: 'link',
        dataCy: 'menuMemberareaColors',
        state: 'preview/colors',
        icon: 'hero-swatch',
        permissionModule: ['yourArea'],
      },
      {
        name: 'admin.menuItems.logos.name',
        type: 'link',
        dataCy: 'menuMemberarea',
        state: 'preview/logos',
        icon: 'hero-photo',
        permissionModule: ['yourArea'],
      },
    ],
  },
];

export const MENU2ITEMS: Menu[] = [
  {
    state: 'settings/billing/pricing',
    name: 'admin.menuItems.billingPricing.name',
    type: 'link',
    hint: 'admin.menuItems.billingPricing.hint',
    dataCy: 'menuBilling',
    icon: 'hero-credit-card',
    permissionModule: ['account'],
  },
  {
    state: 'team',
    name: 'admin.menuItems.team.name',
    lgName: 'admin.menuItems.team.name',
    type: 'link',
    dataCy: 'teamManagement',
    icon: 'hero-user-plus',
    permissionModule: ['team'],
  },
  {
    name: 'admin.menuItems.settings.name',
    lgName: 'admin.menuItems.settings.lgName',
    type: 'link',
    dataCy: 'menuSettingsCommon',
    state: 'settings',
    icon: 'hero-cog-8-tooth',
    permissionModule: ['settings', 'messages'],
  },
];
